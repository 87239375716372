import React from 'react';
import { Field } from 'formik';

import { InputUI, DatePickerUI, AutoCompleteSelectUI, FormErrorUI, FormLabelUI } from '../Interface';

const ComplaintFields = ({ viewField, values, validateField, setFieldValue, errors, autocompleteData }) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-2 mx-4">
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="prefixedId"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Sorszám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.prefixedId} />}
          disabled
          InputLabelProps={{ shrink: values.prefixedId !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="date"
          name="date"
          component={DatePickerUI}
          value={values.date}
          fullWidth
          label={<FormLabelUI text="Érkezés dátuma" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.date} />}
          onChange={(date) => {
            setFieldValue('date', date).then(() => {
              validateField('date');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.date !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="customer"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Vevő neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.customer} />}
          onChange={(_e, newVal) => {
            setFieldValue('customer', newVal?.value ?? null).then(() => {
              validateField('customer');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.customer !== '' }}
          selectedValue={values.customer}
          dataset={autocompleteData.businessPartners}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="item"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Reklamált termék megnevezése" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.item} />}
          onChange={(_e, newVal) => {
            setFieldValue('item', newVal?.value ?? null).then(() => {
              validateField('item');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.item !== '' }}
          selectedValue={values.item}
          dataset={autocompleteData.items}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
    </div>
  );
};

export default ComplaintFields;
